/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpPost } from 'common/services'
import { Button, Modal, ModalContent, ModalFooter, ModalHeader } from 'inspinia-template'
import { createRef, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { CellMouseDownEvent, ColDef } from 'ag-grid-community'
import { AG_GRID_LOCALE_PT } from '@ag-grid-community/locale'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'

import { Editor } from '@tinymce/tinymce-react'

import { AgGridWithChangeTracking } from '../components/AgGridWithChangeTracking'
import { ProductCategorySelector } from '../components/ProductCategorySelector'
import { ProductColorSelector } from '../components/ProductColorSelector'
import { Anymarketv2ProductCharacteristic } from '../features/anymarket/types'
import { useListAll } from '../services/api'

import { CustomEditor } from './customeditor'
import { CompoundValueCellRenderer } from './grid-utils'

import { ModalFotos } from './ModalFotos'
import PopupCellRenderer from './MenuCellRenderer'
import { ProdutoZuma } from '../features/zuma/types'
import { NameCellRenderer } from './PopupCellRenderer'
import { ProductContextualMenu } from '../components/ProductContextualMenu'

export function GridProdutos() {
  const gridRef = useRef<AgGridReact>(null)

  const localeText = AG_GRID_LOCALE_PT

  const pagination = true
  const paginationPageSize = 50
  const paginationPageSizeSelector = [50, 100, 200]

  const instances = useListAll<ProdutoZuma[]>('/zuma/products')

  const corRef = createRef<HTMLInputElement>()

  const cNameRef = createRef<HTMLInputElement>()
  const cValueRef = createRef<HTMLInputElement>()

  useEffect(() => {
    if (!instances.isFetched && !instances.isFetching) {
      instances.refetch()
    }
  })

  const [modalAdicionarCor, setModalAdicionarCor] = useState(false)
  const [modalAdicionarCaracteristica, setModalAdicionarCaracteristica] = useState(false)
  const [modalEditor, setModalEditor] = useState(false)
  const [modalFotos, setModalFotos] = useState(false)

  const [id, setId] = useState(0)

  const editorRef = useRef<Editor | null>(null)

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      editable: true,
      enableCellChangeFlash: true,
      filter: true,
      floatingFilter: false,
    }
  }, [])

  useEffect(() => {
    if (instances.data) {
      setRowData(instances.data)
    }
  }, [instances.data])

  const rowClassRules = {
    'bg-info': (params: any) => params.data.estoque === 0,
  }

  //   {data?.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState<ProdutoZuma[]>([])

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef[]>([
    {
      headerName: 'Ações',
      colId: 'action',
      cellRenderer: PopupCellRenderer,
      cellRendererParams: {
        setId: setId,
        setShowModalFotos: setModalFotos,
      },
      pinned: 'left',
      editable: false,
      filter: false,
      sortable: false,
      maxWidth: 70,
    },
    { field: 'codigoNoFornecedor', headerName: 'SKU', editable: false, pinned: true },
    { field: 'sku', headerName: 'Agrupador' },
    {
      field: 'gtinEmbalagem',
      headerName: 'EAN/GTIN',
      colId: 'gtinEmbalagem',
      // cellRenderer: ProductContextualMenu,
    },
    { field: 'classFiscal', headerName: 'NBM' },
    { field: 'marca', editable: false, flex: 3 },
    { field: 'modelo', headerName: 'Modelo', flex: 3 },
    {
      colId: 'nomeCor',
      field: 'nomeCor',
      //valueGetter: (p) => p.data.cor + ';' + p.data.nomeCor,
      headerName: 'Cor',
      cellRenderer: CompoundValueCellRenderer,
      cellEditor: ProductColorSelector,
      cellEditorPopup: true,
      cellEditorPopupPosition: 'under',
    },
    {
      field: 'titulo',
      headerName: 'Título',
      flex: 10,
    },
    {
      field: 'descricaoCurta',
      headerName: 'Descrição',
      cellEditor: CustomEditor,
      cellEditorPopup: true,
      cellEditorPopupPosition: 'under',
      cellEditorParams: {
        setShowModal: setModalEditor,
        editorRef: editorRef,
        gridRef: gridRef,
      },
      comparator: (a, b) => (a.value < b.value ? -1 : 1),
    },
    {
      field: 'categorias',
      headerName: 'Categoria Anymarket',
      cellRenderer: CompoundValueCellRenderer,
      cellEditor: ProductCategorySelector,
      cellEditorParams: {
        path: '/backend/anymarket/categories',
      },
      cellEditorPopup: true,
      cellEditorPopupPosition: 'under',
      comparator: (a, b) => (a.value < b.value ? -1 : 1),
    },
    {
      field: 'categoriasb2b',
      headerName: 'Categoria Tray',
      cellRenderer: CompoundValueCellRenderer,
      cellEditor: ProductCategorySelector,
      cellEditorParams: {
        path: '/backend/tray/categories',
      },
      cellEditorPopup: true,
      cellEditorPopupPosition: 'under',
      comparator: (a, b) => (a.value < b.value ? -1 : 1),
    },
    { field: 'pesoEmbalagem', headerName: 'Peso (Kg)', valueFormatter: (p) => p.value.toFixed(2) },
    { field: 'volumes', headerName: 'Volumes' },
    {
      field: 'profundidade',
      headerName: 'Profundidade (cm)',
      cellStyle: (p: any) => {
        if (p.value === 0) {
          return { backgroundColor: '#ED5565' }
        }
        return null
      },
      valueFormatter: (p) => p.value.toFixed(0),
    },
    {
      field: 'largura',
      headerName: 'Largura (cm)',
      cellStyle: (p: any) => {
        if (p.value === 0) {
          return { backgroundColor: '#ED5565' }
        }
        return null
      },
      valueFormatter: (p) => p.value.toFixed(0),
    },
    {
      field: 'altura',
      headerName: 'Altura (cm)',
      cellStyle: (p: any) => {
        if (p.value === 0) {
          return { backgroundColor: '#ED5565' }
        }
        return null
      },
      valueFormatter: (p) => p.value.toFixed(0),
    },
    {
      field: 'garantiaFornecedor',
      headerName: 'Tempo de garantia',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 180,
      },
    },
    { field: 'estoque', headerName: 'Estoque', editable: false },
    {
      field: 'precoCusto',
      headerName: 'Custo',
      editable: false,
      valueFormatter: (p: any) => (p.value ? 'R$ ' + p.value.toLocaleString() : ''),
    },
    { field: 'crossdocking', headerName: 'Prazo adicional' },
  ])

  const handleAdicionarCaracteristica = () => {
    setModalAdicionarCaracteristica(true)
    // gridRef.current?.api.forEachNodeAfterFilterAndSort((rowNode) => {
    //   console.log(rowNode.data.codigoNoFornecedor)
    // })
  }

  const handleAdicionarCor = () => {
    setModalAdicionarCor(true)
  }

  return (
    <div
      className="ag-theme-quartz" // applying the Data Grid theme
      style={{ height: '92%' }} // the Data Grid will fill the size of the parent container
    >
      <div className="float-left">
        <Button
          disabled={!instances.isFetched}
          size="small"
          variant="default"
          onClick={() => {
            handleAdicionarCaracteristica()
          }}
        >
          Adicionar característica
        </Button>
        <Button
          className="m-l-xs"
          disabled={!instances.isFetched}
          size="small"
          variant="default"
          onClick={() => {
            handleAdicionarCor()
          }}
        >
          Adicionar cor
        </Button>
      </div>
      <AgGridWithChangeTracking
        autoSizeStrategy={{
          type: 'fitCellContents',
        }}
        className="m-t-sm"
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        localeText={localeText}
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        ref={gridRef}
        rowClassRules={rowClassRules}
        rowData={rowData}
        // onCellMouseDown={(params: CellMouseDownEvent) => {
        //   console.log(params)
        //   if (params.event && params.event.button && params.event.button === 2) {
        //     const cellRendererInstances = params.api.getCellRendererInstances({
        //       columns: ['gtinEmbalagem'],
        //       rowNodes: [params.node],
        //     })
        //     console.log(cellRendererInstances)

        //     if (cellRendererInstances.length > 0) {
        //       const instance = cellRendererInstances[0]
        //       console.log('will call')
        //       instance.togglePopup(params.event.target)
        //     }
        //   }
        // }}
      />
      <Modal isOpen={modalAdicionarCaracteristica} setOpen={setModalAdicionarCaracteristica}>
        <ModalHeader title="Adicionar característica" setOpen={setModalAdicionarCaracteristica} />
        <ModalContent className="">
          <div className="alert alert-danger">
            <a className="alert-link" href="#">
              {gridRef.current?.api?.getDisplayedRowCount()}
            </a>{' '}
            produtos selecionados para adicionar característica.
          </div>
          <div className="form-group">
            <label>Nome</label>
            <input className="form-control full-width" ref={cNameRef} type="text" />
          </div>
          <div className="form-group">
            <label>Valor</label>
            <input className="form-control full-width" ref={cValueRef} type="text" />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            label="Salvar"
            variant="primary"
            onClick={() => {
              const updated: number[] = []
              gridRef.current?.api.forEachNodeAfterFilterAndSort((rowNode) => {
                console.log(rowNode.data.codigoNoFornecedor + ' - ' + rowNode.data.sku)
                const id = rowNode.data.sku > 0 ? rowNode.data.sku : rowNode.data.codigoNoFornecedor
                const categoryId = rowNode.data.categorias?.split(';')[0]

                if (updated.indexOf(id) == -1) {
                  updated.push(id)

                  httpPost('/backend/zuma/characteristics/' + id, [
                    {
                      name: cNameRef.current?.value,
                      value: cValueRef.current?.value,
                    },
                  ] as Anymarketv2ProductCharacteristic[]).then((resp) => {
                    if ((resp.value as any)[0].ResultCode == 200) {
                      toast.success('SKU ' + id + ': característica adicionada/alterada com sucesso', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        icon: true,
                        theme: 'colored',
                        onClose: () => {},
                      })
                    } else {
                      toast.error(
                        'SKU ' +
                          id +
                          ' falhou ao adicionar característica' +
                          ((resp.value as any)[0].ResultCode == 422 ? ': já existe.' : ''),
                        {
                          position: 'top-right',
                          autoClose: false,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          icon: true,
                          theme: 'colored',
                          onClose: () => {},
                          onClick: () => {
                            window.open('/zuma/caracteristicas/' + id + '/' + categoryId, '_blank')
                          },
                        },
                      )
                    }
                  })
                }
              })

              setModalAdicionarCaracteristica(false)
            }}
          />
          <Button
            label="Cancelar"
            variant="default"
            onClick={() => {
              setModalAdicionarCaracteristica(false)
            }}
          />
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalAdicionarCor} setOpen={setModalAdicionarCor}>
        <ModalHeader title="Adicionar cor" setOpen={setModalAdicionarCor} />
        <ModalContent className="">
          <div className="form-group">
            <input className="form-control full-width" ref={corRef} type="text" />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            label="Adicionar"
            variant="primary"
            onClick={() => {
              httpPost('/backend/zuma/colors', {
                nome: corRef.current?.value,
              })
              setModalAdicionarCor(false)
            }}
          />
          <Button
            label="Cancelar"
            variant="default"
            onClick={() => {
              setModalAdicionarCor(false)
            }}
          />
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalEditor} setOpen={setModalEditor} size="large">
        <ModalHeader title="Editar" setOpen={setModalEditor} />
        <ModalContent className="ag-custom-component-popup">
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey="gpl"
            onInit={(_evt, editor) => (editorRef.current = editor)}
            // initialValue={modalValue}
            init={{
              height: 400,
              menubar: false,
            }}
          />
        </ModalContent>
        <ModalFooter>
          <Button id="apply-editor" label="Alterar" variant="primary" />
          <Button
            label="Cancelar"
            variant="default"
            onClick={() => {
              setModalEditor(false)
            }}
          />
        </ModalFooter>
      </Modal>
      <ModalFotos id={id.toString()} isOpen={modalFotos} setOpen={setModalFotos} />
    </div>
  )
}
