import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  createContext,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react'

export type PositionValues = 'center' | 'end' | 'start'
export type ShapeValues = 'circular' | 'default' | 'pill' | 'rounded' | 'square'
export type SizeValues = 'large' | 'medium' | 'mini' | 'small'
export type VariantValues =
  | 'danger'
  | 'dark'
  | 'default'
  | 'destructive'
  | 'information'
  | 'light'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'link'
export type WeightValues = 'bold' | 'medium' | 'regular' | 'semibold'

export function getShape(name?: string) {
  return name === 'circular' ? 'rounded-circle' : name === 'pill' ? 'rounded-pill' : name !== 'default' ? name : ''
}

export function getVariant(prefix: string, name?: string, suffix?: string) {
  return name
    ? prefix + (name === 'destructive' ? 'danger' : name === 'information' ? 'info' : name) + (suffix ?? '')
    : ''
}

export function getVariantText(name?: string): string {
  return name === 'light' ? 'text-dark' : ''
}

type NumberAttr = number | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12'

export type ActivityStreamProps = ComponentPropsWithoutRef<'div'>

export type ActivityStreamItemProps = ComponentPropsWithoutRef<'div'> & {
  badge?: ReactNode
  description?: ReactNode
  info?: string
}

export type AgileBoardListProps = ComponentPropsWithoutRef<'ul'>

export type AgileBoardListItemProps = ComponentPropsWithoutRef<'li'> & {
  variant?: VariantValues
}

export type BadgeProps = ComponentPropsWithoutRef<'span'> & {
  shape?: ShapeValues
  variant?: VariantValues
}

export type BreadcrumbProps = ComponentPropsWithoutRef<'ol'>

export type BreadcrumbItemProps = ComponentPropsWithoutRef<'li'> & {
  active?: boolean
}

export type ButtonGroupProps = ComponentPropsWithoutRef<'div'>

export type ButtonProps = ComponentPropsWithRef<'button'> & {
  block?: boolean
  effect?:
    | 'expand-left'
    | 'expand-right'
    | 'expand-up'
    | 'expand-down'
    | 'contract'
    | 'contract-overlay'
    | 'zoom-in'
    | 'zoom-out'
    | 'slide-left'
    | 'slide-right'
    | 'slide-up'
    | 'slide-down'
  icon?: ReactNode
  iconPosition?: 'start' | 'end'
  label?: string
  ladda?: boolean
  link?: boolean
  outlined?: boolean
  shadow?: boolean
  size?: SizeValues
  shape?: ShapeValues
  variant?: VariantValues
}

export type CardContentProps = ComponentPropsWithoutRef<'div'>

export interface CardContextType {
  collapsible: boolean
  isCollapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export const CardContext = createContext<CardContextType | undefined>(undefined)

export type CardFooterProps = ComponentPropsWithoutRef<'div'>
export type CardHeaderProps = ComponentPropsWithoutRef<'div'> & {
  title?: string
}

export type CardProps = ComponentPropsWithoutRef<'div'> & {
  collapsed?: boolean
  collapsible?: boolean
  variant?: VariantValues
}

export type CheckListItemProps = {
  checked?: boolean
  children?: ReactNode
  className?: string
  title: string
}

export type CheckBoxProps = ComponentPropsWithRef<'input'> & {
  inline?: boolean
  label?: string
}

export type CheckListProps = ComponentPropsWithoutRef<'ul'>

type ColSize = boolean | 'auto' | NumberAttr

export type ColProps = ComponentPropsWithoutRef<'div'> & {
  all?: ColSize
  xs?: ColSize
  sm?: ColSize
  md?: ColSize
  lg?: ColSize
  xl?: ColSize
  xxl?: ColSize
}

export type ComboBoxProps = {
  children?: ReactNode
  className?: string
  defaultValue?: number | string
  label?: string
  multiple?: boolean
  name: string
  onChange?: unknown
  onClick?: React.MouseEventHandler<HTMLLabelElement>
  options?: unknown[]
  placeholder?: string
  required?: boolean
  title?: string
  vertical?: boolean
}

export type DateTimePickerProps = {
  className?: string
  dateIcon: ReactNode
  label?: string
  onChange?: (event: unknown) => void
  timeIcon: ReactNode
  value?: string
  vertical?: boolean
}

export type DatePickerProps = {
  className?: string
  label?: string
  onChange?: (event: unknown) => void
  value?: string
  vertical?: boolean
}

export type DropDownMenuProps = ComponentPropsWithoutRef<'ul'> & {
  children: ReactElement[]
  show: boolean
}

export type FlipSwitchProps = ComponentPropsWithoutRef<'input'> & {
  label?: string
  options?: 'onoff' | 'yesno'
  variant?: VariantValues
  vertical?: boolean
}

export type LabelProps = ComponentPropsWithoutRef<'span'> & {
  shape?: ShapeValues
  variant?: VariantValues
}

export type MenuItemProps = ComponentPropsWithoutRef<'li'> & {
  label?: string | ReactNode
  target?: string
  onClick?: React.MouseEventHandler
}

export type ModalContentProps = ComponentPropsWithoutRef<'div'>

export type ModalFooterProps = ComponentPropsWithoutRef<'div'>

export type ModalHeaderProps = ComponentPropsWithoutRef<'div'> & {
  icon?: ReactNode
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
}

export type ModalProps = ComponentPropsWithoutRef<'div'> & {
  isOpen: boolean
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  size?: SizeValues
}

export type PageWrapperProps = ComponentPropsWithoutRef<'div'>

export type PanelContentProps = ComponentPropsWithoutRef<'div'>

interface PanelContextType {
  collapsible: boolean
  isCollapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export const PanelContext = createContext<PanelContextType | undefined>(undefined)

export type PanelHeaderProps = ComponentPropsWithoutRef<'div'>

export type PanelHeaderActionsProps = ComponentPropsWithoutRef<'div'>

export type PanelFooterProps = ComponentPropsWithoutRef<'div'>

export type PanelProps = ComponentPropsWithoutRef<'div'> & {
  collapsed?: boolean
  collapsible?: boolean
}

export type PasswordProps = ComponentPropsWithRef<'input'> & {
  expose?: 'peek' | 'toggle'
  label?: string
  maskIcon?: ReactNode
  revealIcon: ReactNode
  vertical?: boolean
}

export type ProgressBarProps = ComponentPropsWithoutRef<'div'> & {
  max?: number
  min?: number
  size?: SizeValues
  stacked?: boolean
  value: number
  variant?: VariantValues
}

export type ProgressBarStackProps = ComponentPropsWithoutRef<'div'>

export type RadioGroupProps = ComponentPropsWithRef<'div'> & {
  children: ReactElement[]
  defaultValue?: number | string
  name: string
  label?: string
  radioWrapClassName?: string
  radioWrapTag?: string
  value?: number | string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export type RadioProps = ComponentPropsWithRef<'input'> & {
  inline?: boolean
  label?: string
}

export type RowProps = ComponentPropsWithoutRef<'div'>

export type SelectProps = InputHTMLAttributes<HTMLSelectElement> & {
  backspaceRemovesValue?: boolean
  children?: ReactNode
  className?: string
  clearIcon?: ReactNode
  closeMenuOnSelect?: boolean
  defaultValue?: unknown
  dropdownIcon?: ReactNode
  escapeClearsValue?: boolean
  filterOption?: (option: unknown, inputValue: string) => boolean
  formatOptionLabel?: (item: unknown) => ReactNode
  label?: string
  model?: string
  multi?: boolean
  options?: unknown[]
  placeholder?: string
  required?: boolean
  searchable?: boolean
  vertical?: boolean
}

export type SidebarContentProps = ComponentPropsWithoutRef<'div'>

export type SidebarFooterProps = ComponentPropsWithoutRef<'div'>

export type SidebarHeaderProps = ComponentPropsWithoutRef<'li'>

export type SidebarProps = ComponentPropsWithoutRef<'nav'>

export type SplitButtonProps = ComponentPropsWithoutRef<'div'> & {
  children: ReactElement | ReactElement[]
  defaultValue?: number | string
  onChange?: (event: unknown) => void
  label?: ReactElement
  placeholder?: string
  title?: string
  updateLabel?: boolean
}

export type SplitButtonMenuProps = ComponentPropsWithoutRef<'ul'> & {
  children: ReactElement[]
  onClick?: (value: unknown) => void
  setValue?: (value: unknown, label: unknown) => void
  show?: boolean
}

export type TabProps = {
  active?: boolean
  badge?: string
  badgeClass?: string
  badgePosition?: 'start' | 'end'
  children?: ReactNode
  className?: string
  icon?: ReactNode
  iconPosition?: 'start' | 'end'
  label?: string
}

export type TabHeaderProps = TabProps & {
  index: number
  setActiveTab?: (index: number) => void
}

export type TabSetProps = {
  children: ReactElement[]
  className?: string
  headerPosition?: 'top' | 'left' | 'right'
}

export type TagProps = ComponentPropsWithoutRef<'span'>

export type TemplateWrapperProps = ComponentPropsWithoutRef<'div'>

export type TextProps = ComponentPropsWithRef<'input'> & {
  'data-mask'?: string
  help?: string
  label?: string
  vertical?: boolean
}

export type TextAreaProps = ComponentPropsWithRef<'textarea'> & {
  help?: string
  label?: string
  vertical?: boolean
}

export type TimePickerProps = {
  className?: string
  label?: string
  onChange?: (event: unknown) => void
  value?: string
  vertical?: boolean
}

export type TitleProps = ComponentPropsWithoutRef<'h1'> & {
  order: number
  variant?: VariantValues
}

export type ToggleButtonProps = ButtonProps & {
  onChange?: (event: unknown) => void
}

export type ToggleGroupOptionProps = {
  active?: boolean
  children?: ReactNode
  className?: string
  label?: string
  onClick?: React.MouseEventHandler<HTMLLabelElement>
  title?: string
  value: number | string
}

export type ToggleGroupProps = ComponentPropsWithoutRef<'input'> & {
  children?: ReactElement[]
  label?: string
  onChange?: (event: unknown) => void
  vertical?: boolean
}

export type WidgetProps = ComponentPropsWithoutRef<'div'> & {
  icon?: ReactNode
  shape?: ShapeValues
  variant?: VariantValues
}

export type WizardProps = ComponentPropsWithoutRef<'div'> & {
  children: ReactElement[]
  onSubmit: () => void
}

export type WizardStepProps = {
  children: ReactNode
}
