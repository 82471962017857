import classNames from 'classnames'
import { ModalProps } from 'common'

export function Modal(props: ModalProps) {
  const { isOpen, ...divProps } = props

  const className = classNames('modal', 'inmodal', props.className, isOpen ? 'show' : '')

  return (
    <div
      {...divProps}
      className={className}
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      style={{ ...props.style, display: isOpen ? 'block' : 'none' }}
    >
      <div className={props.size && props.size === 'large' ? 'modal-dialog modal-lg' : 'modal-dialog'}>
        <div className="modal-content animated bounceInRight" style={{ ...props.style }}>
          {props.children}
        </div>
      </div>
    </div>
  )
}
